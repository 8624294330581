import React, { useState } from 'react'
import antrag from '../files/JugendmusikzugAnmeldeFormular.pdf'

function Mitglied() {

  const [jugend, setJugend] = useState(true);

  const jugendMusikzug =
    <div className='flex sm:flex-row flex-col mx-5 justify-between'>
      <p className='sm:w-3/5  text-justify'>
        Hast du Lust, ein Instrument zu lernen?
        Möchtest du mit Anderen zusammen musizieren und dabei Spaß haben?
        Sprich uns gerne an oder gib das <a href={antrag} download="Eintreten.pdf"> <b> <i className='hover:underline text-red-800'>Formular</i></b> </a> ausgefüllt bei uns ab!
        Du kannst natürlich auch unser <a href="/kontakt"> <b> <i className='hover:underline text-red-800'>Kontaktseite</i></b> </a> nutzen
        und unter Angabe von Name, Alter, Notenkentnissen, Wunschinstrument und Anschrift eine Nachricht senden.
        <br />
        <br />
        <b> Telefon</b>: 04153/54619
        <br />
        <br />
        Die Ausbildung der Jugendlichen erfolgt in Einzel- und Gruppenunterricht von professionellen Musiklehrern.
      </p>
      <div className='sm:w-1/3 sm:border-l sm:border-t-0 border-t border-l-0 sm:pl-5 border-zinc-500 mt-5 pt-5 sm:mt-0 sm:pt-0'>
        <h1 className='font-semibold text-lg'>Neuer Anfängerkurs!</h1>
        <h2 className=' font-semibold mt-3'>Start ab Mitte September</h2>
        <h2 className='font-semibold mt-3'>Instrumentenkarussel</h2>
        <p>
          Wenn Du Dir wegen des Instrumentes noch nicht sicher bist, komm doch einfach am <b>21.08.2024 zwischen 16 und 18 Uhr</b> ins Gemeindehaus nach <b>Lanze (Dorfstr. 8)</b> und lerne dort verschiedene Instrumente kennen.<br />
          Anmeldung ist erwünscht aber nicht zwingen: <br />
          <a href="/kontakt"> <b> <i className='hover:underline text-red-800'>Kontaktseite</i></b> </a> oder Tel: <b><i className='text-red-800'>04153/54619</i></b>
        </p>

      </div>
    </div>
  const musikzug =
    <div className='flex sm:flex-row flex-col mx-5 justify-between'>
      <p className='sm:w-1/2 text-justify'>
        Wenn Sie Interesse an einer Mitgliedschaft im Feuerwehrmusikzug Lauenburg-Süd haben, nehmen Sie gerne jederzeit über die
        <a href="/kontakt"> <b> <i className='hover:underline text-red-800'>Kontaktseite</i></b> </a>
        Kontakt zu uns auf, rufen Sie an oder besuchen Sie uns persönlich bei einer unserer Proben!
        <br />
        <br />
        <b> Telefon</b>: 04153/54619
      </p>
      <div className='sm:w-1/3 sm:border-l sm:border-t-0 border-t border-l-0 sm:pl-5 border-zinc-500 mt-5 pt-5 sm:mt-0 sm:pt-0'>
        <h1 className='font-semibold text-lg'>Proben</h1>
        <h2 className=' font-semibold mt-3'>Zeit</h2>
        <p>Donnerstags von 19:30 Uhr bis 21:30 Uhr</p>
        <h2 className='font-semibold mt-3'>Ort</h2>
        <p>Dorfstraße 8, 21483 Lanze</p>
      </div>
    </div>


  return (
    <main className="px-5 sm:px-16 sm:pt-10 pt-5 font-light w-full">
      <h1 className='text-2xl font-semibold mb-5 w-auto m-auto text-center'>Du willst Teil des Musikzugs werden?</h1>
      <div className='w-full flex flex-row font-semibold text-lg'>
        <button onClick={() => { setJugend(true) }} className={` w-1/2 py-3 text-center  ${jugend ? "border-t-2 border-x border-red-800 text-red-800" : "border-t bg-gray-100 border-white border-b border-b-red-800"}`}>Jugendmusikzug</button>
        <button onClick={() => { setJugend(false) }} className={`w-1/2 py-3 text-center ${!jugend ? "border-t-2 border-x border-red-800 text-red-800" : "border-t-2 bg-gray-100 border-white border-b border-b-red-800"}`}>Musikzug</button>
      </div>
      <div className='py-5'>
        {jugend ? jugendMusikzug : musikzug}
      </div>

    </main>
  )
}

export default Mitglied