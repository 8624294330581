import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Startseite from './components/Startseite';
import Impressum from './components/Impressum';
import Jugendmusikzug from './components/Jugendmusikzug';
import Termine from './components/Termine';
import Mitglied from './components/Mitglied';
import Chronik from './components/Chronik';
import Foerdern from './components/Foerdern';
import Buchen from './components/Buchen';
import Modal from './components/Modal';
import Kontakt from './components/Kontakt';
import Bestellen from './components/Bestellen';
import DSGVO from './components/DSGVO';

function App() {
  return (
    <BrowserRouter>
      <div className='flex flex-col flex-end min-h-screen '>
        <Header />
        <main className="flex-grow flex justify-center self-center w-full overflow-x-hidden">
          <div className={(window.location.pathname==="/startseite"||window.location.pathname==="/")?"w-full":"max-w-7xl w-full"}> 
            <Routes>
              <Route path="/" element={<Startseite />} exact />
              <Route path="/startseite" element={<Startseite />} exact />
              <Route path="/jugendmusikzug" element={<Jugendmusikzug />} exact />
              <Route path="/termine" element={<Termine />} exact />
              <Route path="/mitglied" element={< Mitglied />} exact />
              <Route path="/impressum" element={<Impressum />} exact />
              <Route path="/chronik" element={<Chronik />} exact />
              <Route path="/foerdern" element={<Foerdern />} exact />
              <Route path='/buchen' element={<Buchen />} exact />
              <Route path='/kontakt' element={<Kontakt />} exact />
              <Route path='/bestellen' element={<Bestellen/>} exact />
              <Route path='/dsgvo' element={<DSGVO/>} exact />
              <Route component={Error} />
            </Routes>
          </div>
        </main>
        <Modal />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
